import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledProfileHeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  paddingTop: theme.spacing(5),
  '& .container': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export const StyledNameContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .name': {
    width: '100%',
  },
}));
