import { styled } from '@mui/system';
import { Card } from '@mui/material';

export const StyledContainer = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.white,
  borderRadius: theme.spacing(3),
  width: '100%',
  maxWidth: '22rem',
  margin: '0 auto',
  gap: theme.spacing(6),
  padding: theme.spacing(8, 4),

  '& .titleBlock': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    textAlign: 'center',
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },

  '& .bottomLink': {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  '& .link': {
    cursor: 'pointer',
  },

  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(8, 6),
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(8),
    padding: theme.spacing(10, 6),
    maxWidth: '35rem',
    '& .titleBlock': {
      gap: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(12),
  },
}));
