import { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'notyf/notyf.min.css';

import { UserProvider, Router, PATHS, PathTypes } from 'app';

ReactGA.initialize('G-2L6GTFW4WD');
export const App: FC = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: { pathname } });
  }, [pathname]);

  useEffect(() => {
    // @ts-ignore
    if (
      !Object.values(PATHS).includes(pathname as PathTypes) &&
      !pathname.startsWith('/profile') &&
      !pathname.startsWith('/load')
    ) {
      navigate(PATHS.home);
    }
  }, [pathname, search]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  useEffect(() => {
    const lastRoute = sessionStorage.getItem('lastRoute') || PATHS.home;
    navigate(lastRoute);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('lastRoute', `${pathname}${search}`);
  }, [pathname, search]);

  return (
    <UserProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <Router />
      </LocalizationProvider>
    </UserProvider>
  );
};
