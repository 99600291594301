import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, Typography } from '@mui/material';

import { ICountryOption } from 'types';
import { COUNTRIES, countryToFlag, getCountryById } from 'utils';
import { useTranslation } from 'react-i18next';

interface ICountrySelectProps {
  control: any;
  defaultValue?: string;
  name: 'countryFrom' | 'countryTo';
  label?: string;
  required?: boolean;
  placeholder?: string;
}

export const CountrySelect: FC<ICountrySelectProps> = ({
  control,
  defaultValue,
  name,
  label,
  required = false,
  placeholder = 'Uzbekistan',
}) => {
  const { t } = useTranslation(['translation', 'countries']);
  const [innerValue, setInnerValue] = useState<ICountryOption | null>(
    defaultValue ? getCountryById(defaultValue)! : null,
  );

  const handleChange = (
    newCountry: ICountryOption | null,
    onChange: (newValue?: string) => void,
  ) => {
    setInnerValue(newCountry);
    onChange(newCountry?.value);
  };

  useEffect(() => {
    if (defaultValue) {
      setInnerValue(getCountryById(defaultValue)!);
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        required: required && t('This field is required'),
      }}
      render={({ field }) => {
        const { onChange } = field;
        return (
          <Autocomplete
            options={COUNTRIES}
            value={innerValue}
            getOptionLabel={(option: ICountryOption) => t(option.label, { ns: 'countries' })}
            renderOption={(props, option: ICountryOption) => {
              return (
                <li {...props} key={option.value}>
                  {countryToFlag(option.value)}
                  <Typography
                    variant='body1'
                    variantMapping={{ body1: 'span' }}
                    sx={{ marginLeft: '0.5rem' }}
                  >
                    {t(option.label, { ns: 'countries' })}
                  </Typography>
                </li>
              );
            }}
            onChange={(_e, newCountry) => handleChange(newCountry, onChange)}
            size='small'
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label={label}
                placeholder={placeholder}
              />
            )}
          />
        );
      }}
    />
  );
};
