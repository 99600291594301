import { FC } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Divider, Typography } from '@mui/material';
import { TextField, Button } from 'UI';
import { useUpdatePassword } from 'hooks';
import { IUpdatePassword } from 'types';
import { notify } from 'utils';

import {
  StyledContainer,
  StyledBody,
  StyledFooter,
  StyledFormItem,
} from '../profileStyledComponents';
import { useTranslation } from 'react-i18next';

interface IUpdatePasswordForm extends IUpdatePassword {
  confirmNewPassword: string;
}

export const ProfilePassword: FC = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const { isLoading, mutateAsync: updatePassword } = useUpdatePassword();

  const resetData = () => {
    reset();
  };

  const saveData: SubmitHandler<Partial<IUpdatePasswordForm>> = data => {
    if (
      !data.currentPassword ||
      !data.newPassword ||
      !data.confirmNewPassword
    ) {
      notify('error', t('All fields are required'));
      return;
    }
    if (data.newPassword !== data.confirmNewPassword) {
      notify('error', t('Passwords do not match'));
      return;
    }

    updatePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    })
      .then(() => {
        notify('success', t('Password updated'));
        reset();
      })
      .catch(() => {
        notify('error', t('Password update failed'));
      });
  };

  return (
    <StyledContainer>
      <StyledBody>
        <StyledFormItem>
          <Typography variant='body1' variantMapping={{ body1: 'label' }}>
            {t('Current password')}
          </Typography>
          <Controller
            control={control}
            name='currentPassword'
            render={({ field }) => {
              const { name, onChange, onBlur, ref, value } = field;
              return (
                <TextField
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  value={value}
                  placeholder={t('Current password')}
                  className='input'
                  type='password'
                />
              );
            }}
          />
        </StyledFormItem>
        <Divider orientation='horizontal' />
        <StyledFormItem>
          <Typography variant='body1' variantMapping={{ body1: 'label' }}>
            {t('New Password')}
          </Typography>
          <Controller
            control={control}
            name='newPassword'
            render={({ field }) => {
              const { name, onChange, onBlur, ref, value } = field;
              return (
                <TextField
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  value={value}
                  placeholder={t('New Password')}
                  className='input'
                  type='password'
                />
              );
            }}
          />
        </StyledFormItem>
        <Divider orientation='horizontal' />
        <StyledFormItem>
          <Typography variant='body1' variantMapping={{ body1: 'label' }}>
            {t('Confirm New Password')}
          </Typography>
          <Controller
            control={control}
            name='confirmNewPassword'
            render={({ field }) => {
              const { name, onChange, onBlur, ref, value } = field;
              return (
                <TextField
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  value={value}
                  placeholder={t('Confirm New Password')}
                  className='input'
                  type='password'
                />
              );
            }}
          />
        </StyledFormItem>
      </StyledBody>
      <StyledFooter>
        <Button
          onClick={resetData}
          variant='outlined'
          color='primaryBlue'
          size='large'
          className='button'
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleSubmit(saveData)}
          variant='contained'
          color='primaryBlue'
          size='large'
          className='button'
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('Save')}
        </Button>
      </StyledFooter>
    </StyledContainer>
  );
};
