import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

export const StyledHeader = styled('header')(({ theme }) => ({
  padding: theme.spacing(6, 0),
  '& .container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  height: '6.25rem',
}));

export const StyledLeftSide = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledRightSide = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(8),
  alignItems: 'center',
  '& .logo': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    width: '100%',
    '& .logo': {
      display: 'block',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const StyledLoginButtonBox = styled(Box)(({ theme }) => ({
  minWidth: '8rem',
  '& button': {
    width: '100%',
  },
  '& .userProfileIcon': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'auto',
    '& button': {
      display: 'none',
    },
    '& .userProfileIcon': {
      display: 'block',
    },
  },
}));

export const StyledProfileBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  '& .name': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const StyledLanguageSwitcherBox = styled(Box)(() => ({
  '& svg': {
    width: '16px',
    height: '16px',
  },
}));

export const StyledLanguagesList = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledLanguageButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'flex-start',
  fontWeight: 400,
  '& svg': {
    width: '16px',
    height: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    '&.popoverButton': {
      width: '100%',
    },
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  '& p': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
