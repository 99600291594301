import { styled } from '@mui/system';
import { Box, Paper } from '@mui/material';

export const StyledContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.white,
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'calc(100vh - 25.5rem)',
  [theme.breakpoints.down('sm')]: {
    minHeight: 'calc(100vh - 37rem)',
    height: 'auto',
  },
}));

export const StyledBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
}));

export const StyledFormItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '45rem',
  '& label': {
    width: theme.spacing(50),
  },
  '& .input': {
    width: theme.spacing(100),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& label': {
      width: '100%',
    },
    '& .input': {
      width: '100%',
    },
  },
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(3),
  paddingTop: theme.spacing(5),
  borderTop: `1px solid ${theme.palette.gray[200]}`,
  '& .button': {
    width: '10rem',
  },
}));
