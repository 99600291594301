import { styled } from '@mui/system';
import { Box, Paper } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}));

export const StyledFormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(5),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.white,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
}));

export const StyledFormBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  '& .flex': {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    '&.flex-end': {
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  },
}));

export const StyledFormItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  '& .required': {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 1, 0, 0),
  },
  '& .MuiInputBase-root': {
    height: theme.spacing(10),
    '& input': {
      height: '100%',
      padding: '0 14px',
    },
  },
  '& .MuiInputBase-multiline': {
    height: 'auto',
  },
}));

export const StyledFormFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(3),
  alignItems: 'center',
  '& button': {
    width: '10rem',
  },
}));
