import { Notyf } from 'notyf';
import i18n from 'i18n';

export const getErrorMessage = (error: any): string => {
  if (!error) return '';
  if (error.response) {
    return i18n.t(error.response.data.message, { ns: 'errors' });
  }
  return i18n.t(error.message, { ns: 'errors' });
};

export const getCleanedPhone = (phone: string): string => {
  return phone.replace(/[\s()-]/g, '');
};

const notyf = new Notyf({
  duration: 3000,
  dismissible: true,
  position: {
    x: 'right',
    y: 'top',
  },
  types: [
    {
      type: 'success',
    },
    {
      type: 'error',
    },
  ],
});

export const notify = (
  type: 'success' | 'error' = 'error',
  message: string = 'Something went wrong',
) => {
  notyf.open({
    type,
    message,
  });
};
