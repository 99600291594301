import { FC, useState, useEffect, useContext } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ReactGA from 'react-ga4';
import { MenuItem, Select, Box } from '@mui/material';
import { FilterContext } from 'app';
import { FilterIcon } from 'assets';
import { CountrySelect, Button } from 'UI';
import { SORT_OPTIONS } from 'utils';
import { IGetLoadsParams } from 'types';

import { StyledBoardFilterContainer } from './styledComponents';
import { AdvancedFilter } from './AdvancedFilter';
import { useTranslation } from 'react-i18next';

export const BoardFilter: FC = () => {
  const { t } = useTranslation();
  const { filterObject, applyFilter } = useContext(FilterContext);
  const { handleSubmit, control } = useForm({});
  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);

  const submitFilter: SubmitHandler<Partial<IGetLoadsParams>> = data => {
    ReactGA.event({ category: 'User', action: 'Search' });
    applyFilter(data);
  };

  const handleAdvancedFilterOpen = () => {
    setIsAdvancedFilterOpen(true);
  };

  const handleAdvancedFilterClose = () => {
    setIsAdvancedFilterOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <StyledBoardFilterContainer>
      <AdvancedFilter
        open={isAdvancedFilterOpen}
        onClose={handleAdvancedFilterClose}
      />
      <Box className='inputs'>
        <CountrySelect
          control={control}
          defaultValue={filterObject.countryFrom}
          name='countryFrom'
          label={t('From')}
          placeholder='Uzbekistan'
        />
        <CountrySelect
          control={control}
          defaultValue={filterObject.countryTo}
          name='countryTo'
          label={t('To')}
          placeholder='Russia'
        />
        <Controller
          control={control}
          name='sortBy'
          defaultValue={filterObject.sortBy}
          render={({ field }) => {
            const { name, onChange, onBlur, ref, value } = field;
            return (
              <Select
                size='small'
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
              >
                {SORT_OPTIONS.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    {t(option.label)}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </Box>

      <Box className='buttons'>
        <Button
          variant='outlined'
          color='primaryBlue'
          onClick={handleAdvancedFilterOpen}
          startIcon={<FilterIcon />}
          size='large'
          className='button moreFiltersButton'
        >
          {t('More filters')}
        </Button>
        <Button
          variant='contained'
          color='primaryBlue'
          size='large'
          className='button'
          onClick={handleSubmit(submitFilter)}
        >
          {t('Search')}
        </Button>
      </Box>
    </StyledBoardFilterContainer>
  );
};
