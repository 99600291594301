import { FC, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { useLogin } from 'hooks';
import { getErrorMessage, theme, getCleanedPhone } from 'utils';
import { ILogin } from 'types';
import { PhoneInput, TextField, Button } from 'UI';

import { CARDS, IAuthCardProps } from '../types';
import { StyledContainer } from './styledComponents';

export const LoginCard: FC<IAuthCardProps> = ({ changeCard }) => {
  const { t } = useTranslation();
  const [loginForm, setLoginForm] = useState<ILogin>({
    phone: '',
    password: '',
  });

  const {
    isLoading: isLoginLoading,
    mutateAsync: loginToApp,
    error: loginError,
    isError: isLoginError,
  } = useLogin();

  const handleFieldChange = (field: keyof ILogin, value: string) => {
    setLoginForm(prev => ({ ...prev, [field]: value }));
  };

  const submitForm = () => {
    const cleanedLoginForm = {
      phone: getCleanedPhone(loginForm.phone),
      password: loginForm.password,
    };
    ReactGA.event({ category: 'User', action: 'Login' });
    loginToApp(cleanedLoginForm)
      .then(() => {})
      .catch(() => {});
  };

  return (
    <StyledContainer>
      <Box className='titleBlock'>
        <Typography variant='h4'>{t('Login')}</Typography>
        <Typography variant='body1'>
          {t('Welcome! Please enter your details.')}
        </Typography>
      </Box>
      <form>
        <PhoneInput
          label={t('Phone number')}
          value={loginForm.phone}
          onChange={e => handleFieldChange('phone', e.target.value)}
          placeholder='+998 '
          name='phone'
          error={isLoginError}
          required
        />
        <TextField
          label={t('Password')}
          value={loginForm.password}
          onChange={e => handleFieldChange('password', e.target.value)}
          type='password'
          name='password'
          error={isLoginError}
          errorMessage={getErrorMessage(loginError)}
          disabled={isLoginLoading}
          required
        />

        <Typography
          align='right'
          variant='captionBold'
          color={theme.palette.primaryBlue.main}
          className='link'
          onClick={() => changeCard(CARDS.RESET_PASSWORD)}
        >
          {t('Forgot password?')}
        </Typography>
      </form>

      <Button
        variant='contained'
        color='primaryBlue'
        size='medium'
        onClick={submitForm}
        disabled={isLoginLoading}
        isLoading={isLoginLoading}
      >
        {t('Continue')}
      </Button>
      <Box className='bottomLink'>
        <Typography variant='caption'>{t('New user?')}</Typography>
        <Typography
          variant='captionBold'
          color={theme.palette.primaryBlue.main}
          className='link'
          onClick={() => changeCard(CARDS.REGISTER)}
        >
          {t('Registration')}
        </Typography>
      </Box>
    </StyledContainer>
  );
};
