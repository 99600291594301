import { FilterProvider } from 'app';
import { Layout, BoardFilter, AddLoadButton, LoadBoard } from 'components';

export const Home = () => {
  return (
    <Layout>
      <FilterProvider>
        <AddLoadButton />
        <BoardFilter />
        <LoadBoard />
      </FilterProvider>
    </Layout>
  );
};
