import { styled } from '@mui/system';
import { Box, DialogTitle, DialogActions, DialogContent } from '@mui/material';

export const StyledBoardFilterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.white,
  borderRadius: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
  '& .buttons': {
    display: 'flex',
    gap: theme.spacing(3),
    '& .button': {
      minWidth: '10rem',
    },
    '& .moreFiltersButton': {
      width: 'max-content',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      '& .button': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  '& .inputs': {
    display: 'flex',
    gap: theme.spacing(3),
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
    '& input': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

export const StyledAdvancedFilterContainer = styled(Box)(({ theme }) => ({
  width: '25rem',
  backgroundColor: theme.palette.white,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.gray[200]}`,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  paddingTop: `${theme.spacing(5)} !important`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(5),
  gap: theme.spacing(3),
  '& button': {
    width: '100%',
  },
}));

export const StyledFormItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  '& .flex': {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));
