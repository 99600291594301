import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { Loader, Button } from 'UI';
import { useGetLoad } from 'hooks';
import { LoadCard } from 'components';

import { StyledContainer } from './styledComponents';
import { PATHS } from 'app';

export const LoadScreen: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetLoad(id);

  if (isLoading) {
    return (
      <StyledContainer>
        <Loader size={60} />
      </StyledContainer>
    );
  }
  if (!data) {
    return (
      <StyledContainer>
        <Typography variant='h5' align='center'>
          {t('No load with such id found')}
        </Typography>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <LoadCard {...data!} isFullInfo />
      <Box my={4} style={{ textAlign: 'center' }}>
        <Link to={PATHS.home}>
          <Button variant='outlined' color='primaryBlue' size='large'>
            {t('See more loads')}
          </Button>
        </Link>
      </Box>
    </StyledContainer>
  );
};
