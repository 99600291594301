import { IGetMyLoadsParams, IUpdateDetails, IUpdatePassword } from 'types';
import { http, methods } from './config';

export const updatePassword = (data: IUpdatePassword) => {
  const config = {
    method: methods.post,
    url: `/user/update-password`,
    data,
  };
  return http(config);
};

export const updateUserDetails = (data: IUpdateDetails) => {
  const config = {
    method: methods.post,
    url: `/user/update-details`,
    data,
  };
  return http(config);
};

export const getMyLoads = (params: IGetMyLoadsParams) => {
  const config = {
    method: methods.get,
    url: `/load/my-loads`,
    params,
  };
  return http(config);
};

