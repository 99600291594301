import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  updateUserDetails,
  updatePassword,
  getMyLoads,
  getLoad,
  createLoad,
  updateLoad,
  deleteLoad,
} from 'api';
import { IGetMyLoadsParams, IGetMyLoadsRes, ILoad, IUser } from 'types';
import { notify } from 'utils';

export const useUpdateUserDetails = () => {
  return useMutation(updateUserDetails, {
    mutationKey: 'updateDetails',
    onSuccess: (res: IUser) => {},
    onError: () => {},
  });
};

export const useUpdatePassword = () => {
  return useMutation(updatePassword, {
    mutationKey: 'updatePassword',
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useGetMyLoads = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IGetMyLoadsRes>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getLoads = async (params: IGetMyLoadsParams) => {
    setIsLoading(true);
    try {
      const res = await getMyLoads(params);
      setData(prev => ({
        ...res,
        content: prev?.content.length
          ? [...prev.content, ...res.content]
          : res.content,
      }));
    } catch (error) {
      notify('error', t('Failed to get loads'));
    } finally {
      setIsLoading(false);
    }
  };

  const resetLoads = () => {
    setData(undefined);
  };

  return { data, getLoads, resetLoads, isLoading };
};

export const useGetMyLoad = (id?: string) => {
  if (!id)
    return useQuery<ILoad>(['getMyLoads', id], async () => {
      return {} as ILoad;
    });
  return useQuery<ILoad>(
    ['getMyLoads', id],
    async () => {
      const res = await getLoad(id);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateLoad = () => {
  const queryClient = useQueryClient();
  return useMutation(createLoad, {
    mutationKey: 'createLoad',
    onSuccess: () => {
      queryClient.invalidateQueries('getMyLoads');
    },
    onError: () => {},
  });
};

export const useUpdateLoad = () => {
  const queryClient = useQueryClient();
  return useMutation(updateLoad, {
    mutationKey: 'createLoad',
    onSuccess: () => {
      queryClient.invalidateQueries('getMyLoads');
    },
    onError: () => {},
  });
};

export const useDeleteLoad = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteLoad, {
    mutationKey: 'deleteLoad',
    onSuccess: () => {
      queryClient.invalidateQueries('getMyLoads');
    },
    onError: () => {},
  });
};
