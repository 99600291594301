import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';

import { PAYMENT_METHODS } from 'utils';
import { PaymentMethodValueType } from 'types';
import { useTranslation } from 'react-i18next';

interface IPaymentSelectProps {
  control?: any;
  defaultValue?: PaymentMethodValueType;
}

export const PaymentSelect: FC<IPaymentSelectProps> = ({
  control,
  defaultValue,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name='paymentType'
      defaultValue={defaultValue}
      render={({ field }) => {
        const { name, onChange, onBlur, ref, value } = field;
        return (
          <Select
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
          >
            {PAYMENT_METHODS.map(option => (
              <MenuItem value={option.value} key={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};
