import { useContext } from 'react';
import { useMutation } from 'react-query';
import {
  login,
  checkSmsCode,
  register,
  resetPassword,
  changePassword,
} from 'api';
import { PATHS, UserContext } from 'app';
import { removeItem, setItem } from 'utils';
import { ILoginRes, ICheckSmsCodeRes } from 'types';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
  const { updateUser } = useContext(UserContext);
  return useMutation(login, {
    mutationKey: 'login',
    onSuccess: (res: ILoginRes) => {
      const { token, user } = res;
      setItem('token', token);
      removeItem('timer');
      updateUser(user);
    },
    onError: () => {},
  });
};

export const useCheckSmsCode = () => {
  return useMutation(checkSmsCode, {
    mutationKey: 'checkSmsCode',
    onSuccess: (res: ICheckSmsCodeRes) => {},
    onError: () => {},
  });
};

export const useRegister = () => {
  return useMutation(register, {
    mutationKey: 'register',
    onSuccess: () => {
      removeItem('timer');
    },
    onError: () => {},
  });
};

export const useResetPassword = () => {
  return useMutation(resetPassword, {
    mutationKey: 'resetPassword',
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useChangePassword = () => {
  return useMutation(changePassword, {
    mutationKey: 'changePassword',
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    removeItem('token');
    removeItem('userData');
    removeItem('lastRoute');
    navigate(PATHS.home);
  };

  return { logout };
};
