import { styled } from '@mui/system';

export const StyledFooter = styled('footer')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  '& .container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
      gap: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'flex-start',
    },
  },
}));

export const StyledItem = styled('a')(({ theme }) => ({
  color: theme.palette.gray[1000],
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  transition: 'color 0.3s ease',
  '&:hover': {
    '& p': {
      color: theme.palette.primaryBlue.main,
    },
    '& svg path': {
      fill: theme.palette.primaryBlue.main,
    },
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: `calc(50% - ${theme.spacing(2)})`,
  },
}));
