import { FC } from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import {
  DatePicker as DatePickerMui,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  '& .errorMessage': {
    color: theme.palette.error.main,
  },
}));

interface ITextFieldProps extends Omit<DatePickerProps<Dayjs>, 'sx'> {
  error?: boolean;
  errorMessage?: string;
  ref: React.Ref<any>;
}

export const DatePicker: FC<ITextFieldProps> = ({
  name,
  value,
  onChange,
  error,
  errorMessage,
  ref,
  ...props
}) => {
  return (
    <StyledContainer>
      <DatePickerMui
        name={name}
        value={value}
        onChange={onChange}
        renderLoading={() => <DayCalendarSkeleton />}
        inputRef={ref}
        {...props}
      />
      {error && (
        <Typography className='errorMessage'>{errorMessage}</Typography>
      )}
    </StyledContainer>
  );
};
