import { FC } from 'react';
import { Layout, LoadScreen } from 'components';

export const Load: FC = () => {
  return (
    <Layout>
      <LoadScreen />
    </Layout>
  );
};
