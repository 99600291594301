import { FC } from 'react';
import { styled } from '@mui/system';
import {
  FormControlLabel,
  Switch as MUISwitch,
  SwitchProps,
} from '@mui/material';

const StyledSwitch = styled(FormControlLabel)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: 0,
  '& span': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
}));

const CustomSwitch = styled((props: SwitchProps) => (
  <MUISwitch
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: theme.palette.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primaryBlue.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primaryBlue.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
  },
}));

interface ISwitchProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const Switch: FC<ISwitchProps> = ({
  label,
  checked,
  onChange,
  disabled,
  className,
  style,
}) => {
  return (
    <StyledSwitch
      label={label}
      labelPlacement='start'
      style={style}
      control={
        <CustomSwitch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={className}
        />
      }
    ></StyledSwitch>
  );
};
