import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import i18n from 'i18n';
import { getItem, getToken, notify, removeItem } from 'utils';

// Create a new Axios instance
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: 30000,
  headers: {},
});

// Request interceptor
axiosInstance.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Process response data as needed
    return response.data;
  },
  (error: AxiosError) => {
    // Handle error responses
    if (error.response) {
      // Handle specific error status codes or conditions
      if (error.response.status === 401 && getItem('token')) {
        removeItem('token');
        removeItem('userData');
        removeItem('lastRoute');
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export const http = (config: AxiosRequestConfig): Promise<any> => {
  return axiosInstance(config)
    .then(res => {
      return res;
    })
    .catch(err => {
      notify('error', i18n.t(err?.response?.data?.message, { ns: 'errors' }));
      throw err;
    });
};

type Methods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const methods: Record<string, Methods> = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
};
