import { FC } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgressProps,
} from '@mui/material';
import { Loader } from 'UI';

interface IButtonProps extends ButtonProps {
  isLoading?: boolean;
  loaderColor?: CircularProgressProps['color'];
}

export const Button: FC<IButtonProps> = ({
  isLoading,
  loaderColor = 'white',
  children,
  ...props
}) => {
  return (
    <MuiButton
      startIcon={isLoading ? <Loader size={12} color={loaderColor} /> : null}
      {...props}
    >
      {children}
    </MuiButton>
  );
};
