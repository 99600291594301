import {
  ISortOption,
  IPaymentMethodOption,
  ICountryOption,
  PaymentMethodValueType,
  SortValueType,
} from 'types';

export const SORT_OPTIONS: Array<ISortOption> = [
  {
    value: 'C',
    label: 'The cheapest',
  },
  {
    value: 'E',
    label: 'The most expensive',
  },
  {
    value: 'N',
    label: 'The newest',
  },
  {
    value: 'O',
    label: 'The oldest',
  },
];

export const SORT_OPTION_VALUES: Record<SortValueType, SortValueType> = {
  C: 'C',
  E: 'E',
  N: 'N',
  O: 'O',
};

export const PAYMENT_METHODS: Array<IPaymentMethodOption> = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'CASH',
    label: 'Cash',
  },
  {
    value: 'CARD',
    label: 'Plastic card',
  },
  {
    value: 'BANK',
    label: 'Bank transfer',
  },
];

export const PAYMENT_METHOD_VALUES: Record<
  PaymentMethodValueType,
  PaymentMethodValueType
> = {
  ALL: 'ALL',
  CASH: 'CASH',
  BANK: 'BANK',
  CARD: 'CARD',
};

export const DEFAULT_PAGE_SIZE: number = 20;

export const getStickerOfPaymentMethod = (val: PaymentMethodValueType) => {
  if (val === 'CASH') {
    return '💵';
  }
  if (val === 'BANK') {
    return '🏦';
  }
  if (val === 'CARD') {
    return '💳';
  }
  return '💰';
};

export const COUNTRIES: Array<ICountryOption> = [
  { value: 'UZ', label: 'Uzbekistan', phone: '998' },
  { value: 'RU', label: 'Russia', phone: '7' },
  { value: 'BY', label: 'Belarus', phone: '375' },
  { value: 'KZ', label: 'Kazakhstan', phone: '7' },
  { value: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { value: 'TJ', label: 'Tajikistan', phone: '992' },
  { value: 'TM', label: 'Turkmenistan', phone: '993' },
  { value: 'EE', label: 'Estonia', phone: '372' },
  { value: 'LT', label: 'Lithuania', phone: '370' },
  { value: 'LV', label: 'Latvia', phone: '371' },
  { value: 'TR', label: 'Turkey', phone: '90' },
  { value: 'CN', label: 'China', phone: '86' },
  { value: 'DE', label: 'Germany', phone: '49', suggested: true },
  { value: 'AE', label: 'United Arab Emirates', phone: '971' },
  { value: 'AF', label: 'Afghanistan', phone: '93' },
  { value: 'AL', label: 'Albania', phone: '355' },
  { value: 'AM', label: 'Armenia', phone: '374' },
  { value: 'AT', label: 'Austria', phone: '43' },
  { value: 'AZ', label: 'Azerbaijan', phone: '994' },
  { value: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { value: 'BD', label: 'Bangladesh', phone: '880' },
  { value: 'BE', label: 'Belgium', phone: '32' },
  { value: 'BG', label: 'Bulgaria', phone: '359' },
  { value: 'BH', label: 'Bahrain', phone: '973' },
  { value: 'CH', label: 'Switzerland', phone: '41' },
  { value: 'CZ', label: 'Czech Republic', phone: '420' },
  { value: 'DK', label: 'Denmark', phone: '45' },
  { value: 'EG', label: 'Egypt', phone: '20' },
  { value: 'ES', label: 'Spain', phone: '34' },
  { value: 'FI', label: 'Finland', phone: '358' },
  { value: 'FR', label: 'France', phone: '33', suggested: true },
  { value: 'GB', label: 'United Kingdom', phone: '44' },
  { value: 'GE', label: 'Georgia', phone: '995' },
  { value: 'GI', label: 'Gibraltar', phone: '350' },
  { value: 'GR', label: 'Greece', phone: '30' },
  { value: 'HK', label: 'Hong Kong', phone: '852' },
  { value: 'HR', label: 'Croatia', phone: '385' },
  { value: 'HU', label: 'Hungary', phone: '36' },
  { value: 'IL', label: 'Israel', phone: '972' },
  { value: 'IN', label: 'India', phone: '91' },
  { value: 'IQ', label: 'Iraq', phone: '964' },
  { value: 'IR', label: 'Iran', phone: '98' },
  { value: 'IT', label: 'Italy', phone: '39' },
  { value: 'JO', label: 'Jordan', phone: '962' },
  { value: 'KH', label: 'Cambodia', phone: '855' },
  { value: 'KR', label: 'Korea', phone: '82' },
  { value: 'KW', label: 'Kuwait', phone: '965' },
  { value: 'LB', label: 'Lebanon', phone: '961' },
  { value: 'LI', label: 'Liechtenstein', phone: '423' },
  { value: 'LU', label: 'Luxembourg', phone: '352' },
  { value: 'MC', label: 'Monaco', phone: '377' },
  { value: 'MD', label: 'Moldova', phone: '373' },
  { value: 'ME', label: 'Montenegro', phone: '382' },
  { value: 'MK', label: 'Macedonia', phone: '389' },
  { value: 'MM', label: 'Myanmar', phone: '95' },
  { value: 'MN', label: 'Mongolia', phone: '976' },
  { value: 'MO', label: 'Macao', phone: '853' },
  { value: 'MY', label: 'Malaysia', phone: '60' },
  { value: 'NL', label: 'Netherlands', phone: '31' },
  { value: 'NO', label: 'Norway', phone: '47' },
  { value: 'NP', label: 'Nepal', phone: '977' },
  { value: 'OM', label: 'Oman', phone: '968' },
  { value: 'PK', label: 'Pakistan', phone: '92' },
  { value: 'PL', label: 'Poland', phone: '48' },
  { value: 'PS', label: 'Palestine', phone: '970' },
  { value: 'PT', label: 'Portugal', phone: '351' },
  { value: 'QA', label: 'Qatar', phone: '974' },
  { value: 'RO', label: 'Romania', phone: '40' },
  { value: 'RS', label: 'Serbia', phone: '381' },
  { value: 'SA', label: 'Saudi Arabia', phone: '966' },
  { value: 'SE', label: 'Sweden', phone: '46' },
  { value: 'SG', label: 'Singapore', phone: '65' },
  { value: 'SI', label: 'Slovenia', phone: '386' },
  { value: 'SK', label: 'Slovakia', phone: '421' },
  { value: 'SM', label: 'San Marino', phone: '378' },
  { value: 'SY', label: 'Syria', phone: '963' },
  { value: 'TH', label: 'Thailand', phone: '66' },
  { value: 'UA', label: 'Ukraine', phone: '380' },
  { value: 'VN', label: 'Vietnam', phone: '84' },
  { value: 'XK', label: 'Kosovo', phone: '383' },
  { value: 'YE', label: 'Yemen', phone: '967' },
];

// @ts-ignore
export function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        // @ts-ignore
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;
}

export const getCountryNameById = (id: string): string => {
  return COUNTRIES.find(country => country.value === id)?.label || '';
};

export const getCountryById = (id: string): ICountryOption | null => {
  return COUNTRIES.find(country => country.value === id) || null;
};
