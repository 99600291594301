import { FC, useState, useRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { Box, Dialog, Divider, Grid, Typography } from '@mui/material';
import { useDeleteLoad } from 'hooks';
import {
  DestinationIcon,
  EditIcon,
  OriginIcon,
  ViewIcon,
  CopyIcon,
  DoneIcon,
} from 'assets';
import { ILoad } from 'types';
import { PAYMENT_METHODS, getCountryNameById } from 'utils';
import { Switch, Button } from 'UI';

import {
  StyledContainer,
  StyledMainInfo,
  StyledPriceButton,
  StyledPhoneButton,
  StyledBody,
  StyledFooter,
  StyledInfo,
  StyledAuthorFooter,
  StyledViewDialogContainer,
  StyledDialogContent,
  StyledDialogActions,
} from './styledComponents';
import { MessageArea } from './MessageArea';

interface ILoadCardProps extends ILoad {
  isAuthorMode?: boolean;
  isFullInfo?: boolean;
  onClick?: () => void;
  className?: string;
}

export const LoadCard: FC<ILoadCardProps> = props => {
  const {
    id,
    countryFrom,
    addressFrom,
    countryTo,
    addressTo,
    loadType,
    weight,
    leaveDate,
    price,
    priceCurrency,
    paymentType,
    advancePayment,
    advancePaymentAmount,
    advancePaymentCurrency,
    description,
    phone,
    active,
    trackInfo,
    createdByName,
    isAuthorMode,
    isFullInfo,
    onClick,
    className = '',
  } = props;
  const { t } = useTranslation();
  const messageAreaRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showFullInfo, setShowFullInfo] = useState<boolean>(!!isFullInfo);
  const [loadStatus, setLoadStatus] = useState<boolean>(active);
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { mutateAsync: deleteLoad } = useDeleteLoad();

  const toggleFullInfo = (e: MouseEvent) => {
    e.stopPropagation();
    ReactGA.event({ category: 'User', action: 'ToggleLoadInfo' });
    setShowFullInfo(!showFullInfo);
  };

  const handlePhoneClick = (e: MouseEvent) => {
    e.stopPropagation();
    window.open(`tel:${phone}`);
  };

  const handleEditClick = (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/profile/load/${id}`);
  };

  const openMessageModal = (e: MouseEvent) => {
    e.stopPropagation();
    setShowMessageModal(true);
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    setIsCopied(false);
  };

  const copyToClipboard = (e: MouseEvent) => {
    e.stopPropagation();
    ReactGA.event({ category: 'User', action: 'CopyLoad' });
    if (messageAreaRef.current) {
      const range = document.createRange();
      range.selectNode(messageAreaRef.current);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }

      try {
        navigator.clipboard.writeText(messageAreaRef.current.innerText);
        setIsCopied(true);
      } catch (err) {
        return;
      } finally {
        // Clear the selection after copying
        if (selection) {
          selection.removeAllRanges();
        }
      }
    }
  };

  const deleteLoadHandler = () => {
    ReactGA.event({ category: 'User', action: 'DeleteLoad' });
    deleteLoad(id).then(() => {
      setLoadStatus(!loadStatus);
    });
  };

  return (
    <StyledContainer onClick={toggleFullInfo} className={className}>
      <Dialog open={showMessageModal} onClose={closeMessageModal}>
        <StyledViewDialogContainer>
          <StyledDialogContent ref={messageAreaRef}>
            <MessageArea {...props} />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button
              variant='outlined'
              color='primaryBlue'
              startIcon={!isCopied ? <CopyIcon /> : <DoneIcon />}
              onClick={copyToClipboard}
            >
              {!isCopied ? t('Copy') : t('Copied')}
            </Button>
          </StyledDialogActions>
        </StyledViewDialogContainer>
      </Dialog>
      <StyledMainInfo>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={5} lg={3}>
            <StyledInfo>
              <Typography variant='caption' className='label'>
                {t('From')}
              </Typography>
              <Typography variant='body1' className='value'>
                <OriginIcon />
                {addressFrom},{' '}
                {t(getCountryNameById(countryFrom), { ns: 'countries' })}
              </Typography>
            </StyledInfo>
          </Grid>
          <Grid item xs={0} sm={2} lg={1} className='space spaceWithLg'></Grid>
          <Grid item xs={12} sm={5} lg={3}>
            <StyledInfo>
              <Typography variant='caption' className='label'>
                {t('To')}
              </Typography>
              <Typography variant='body1' className='value'>
                <DestinationIcon />
                {addressTo},{' '}
                {t(getCountryNameById(countryTo), { ns: 'countries' })}
              </Typography>
            </StyledInfo>
          </Grid>
          <Grid item xs={12} sm={5} lg={2.5}>
            <StyledInfo>
              <Typography variant='caption' className='label'>
                {t('Price')}
              </Typography>
              {price ? (
                <StyledPriceButton color='success'>
                  {price} {priceCurrency}
                </StyledPriceButton>
              ) : (
                <StyledPriceButton color='error'>
                  {t('Not given')}
                </StyledPriceButton>
              )}
            </StyledInfo>
          </Grid>
          <Grid item xs={0} sm={2} lg={0} className='space' />
          <Grid item xs={12} sm={5} lg={2.5}>
            <StyledInfo>
              <Typography variant='caption' className='label'>
                {t('Phone')}
              </Typography>
              <StyledPhoneButton
                onClick={handlePhoneClick}
                variant='contained'
                color='primaryBlue'
              >
                {phone}
              </StyledPhoneButton>
            </StyledInfo>
          </Grid>
        </Grid>
      </StyledMainInfo>
      <Divider orientation='horizontal' />
      {showFullInfo && (
        <StyledBody>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Advance payment')}
                </Typography>
                <Typography variant='body1' className='value'>
                  {advancePayment ? t('Yes') : t('No')}
                  {advancePayment &&
                    ` / ${advancePaymentAmount} ${advancePaymentCurrency}`}
                </Typography>
              </StyledInfo>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Payment method')}
                </Typography>
                <Typography variant='body1' className='value'>
                  {t(
                    PAYMENT_METHODS.find(
                      method => method.value === paymentType,
                    )!.label,
                  )}
                </Typography>
              </StyledInfo>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Leave date')}
                </Typography>
                <Typography variant='body1' className='value'>
                  {leaveDate}
                </Typography>
              </StyledInfo>
            </Grid>
          </Grid>
          <Divider orientation='horizontal' />
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Load type')}
                </Typography>
                <Typography variant='body1' className='value'>
                  {loadType || t('No info')}
                </Typography>
              </StyledInfo>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Weight')}
                </Typography>
                <Typography variant='body1' className='value'>
                  {weight || t('No info')}
                </Typography>
              </StyledInfo>
            </Grid>
          </Grid>
          <Divider orientation='horizontal' />
          <Grid container spacing={6}>
            <Grid item md={6}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Car requirements')}
                </Typography>
                <Typography variant='body1' className='value textArea'>
                  {trackInfo || t('No requirements')}
                </Typography>
              </StyledInfo>
            </Grid>
            <Grid item md={6}>
              <StyledInfo>
                <Typography variant='caption' className='label'>
                  {t('Comments')}
                </Typography>
                <Typography variant='body1' className='value textArea'>
                  {description || t('No comments')}
                </Typography>
              </StyledInfo>
            </Grid>
          </Grid>
          <Divider orientation='horizontal' />
        </StyledBody>
      )}
      {!isAuthorMode ? (
        <StyledFooter>
          <Typography variant='body1'>{createdByName}</Typography>
          {!!onClick && (
            <Button variant='text' color='primaryBlue' onClick={onClick}>
              {t('See load info')}
            </Button>
          )}
        </StyledFooter>
      ) : (
        <StyledAuthorFooter>
          <Box className='switch'>
            <Switch
              label={t('Active')}
              checked={loadStatus}
              onChange={deleteLoadHandler}
            />
          </Box>
          <Box className='buttons'>
            <Button
              variant='outlined'
              color='primaryBlue'
              startIcon={<EditIcon />}
              onClick={handleEditClick}
            >
              {t('Edit')}
            </Button>
            <Button
              variant='contained'
              color='primaryBlue'
              startIcon={<ViewIcon />}
              onClick={openMessageModal}
            >
              {t('View')}
            </Button>
          </Box>
        </StyledAuthorFooter>
      )}
    </StyledContainer>
  );
};
