import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledLoadBoardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(5, 0),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
  },
  '& .buttonBox': {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      minWidth: '10rem',
      '& svg':{
        maxWidth: '1rem',
      }
    },
  },
  '& .notFound': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '& .loadCard': {
    cursor: 'pointer',
  },
}));
