import { FC, ReactNode } from 'react';
import { Container } from '@mui/material';
import { Footer, Header } from 'components';

interface ILayoutProps {
  children: ReactNode;
}

export const Layout: FC<ILayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        <Container fixed maxWidth='xl'>
          {children}
        </Container>
      </main>
      <Footer />
    </>
  );
};
