import { FC } from 'react';
import InputMask from 'react-input-mask';
import { TextFieldProps } from '@mui/material';
import { TextField } from 'UI';

interface IPhoneInputProps extends Omit<TextFieldProps, 'value'> {
  value: string;
  errorMessage?: string;
}

export const PhoneInput: FC<IPhoneInputProps> = ({
  value,
  onChange,
  errorMessage,
  label = 'Phone number',
  ...props
}) => {
  const renderTextField = () => (
    <TextField
      label={label}
      variant='outlined'
      fullWidth
      helperText={errorMessage}
      {...props}
    />
  );

  return (
    <InputMask
      mask='+999 (99) 999-99-99'
      maskChar={null}
      value={value}
      onChange={onChange}
    >
      {/* @ts-ignore */}
      {() => renderTextField()}
    </InputMask>
  );
};
