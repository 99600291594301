import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ILoad } from 'types';
import { countryToFlag, getCountryNameById } from 'utils';
import { PAYMENT_METHODS } from 'utils';

import { StyledMessageArea } from './styledComponents';

interface IMessageAreaProps extends ILoad {}

export const MessageArea: FC<IMessageAreaProps> = ({
  id,
  countryFrom,
  addressFrom,
  countryTo,
  addressTo,
  loadType,
  weight,
  price,
  priceCurrency,
  advancePayment,
  advancePaymentAmount,
  leaveDate,
  paymentType,
  trackInfo,
  phone,
  description,
  createdByName,
}) => {
  const { t } = useTranslation();
  return (
    <StyledMessageArea>
      <div>
        {countryToFlag(countryFrom)} {addressFrom},{' '}
        {t(getCountryNameById(countryFrom), { ns: 'countries' })}
      </div>
      <div>
        {countryToFlag(countryTo)} {addressTo},{' '}
        {t(getCountryNameById(countryTo), { ns: 'countries' })}
      </div>
      <br />
      {trackInfo && <div>🚛 {trackInfo}</div>}
      {loadType && weight && (
        <div>
          📦 {loadType} - {weight}
        </div>
      )}
      <div>📆 {leaveDate || t('No info')}</div>
      <br />
      {price ? (
        <div>
          💰 {price} {priceCurrency}
        </div>
      ) : (
        <div>💰 {t('Price is negotiable')}</div>
      )}
      <div>
        {t(PAYMENT_METHODS.find(method => method.value === paymentType)!.label)}{' '}
        {t('Payment method')}
      </div>
      {advancePayment && (
        <div>
          🔥 {t('Advance payment')} {advancePaymentAmount} {priceCurrency}
        </div>
      )}
      <br />
      {description && <div>❗️ {description}</div>}
      <br />
      <div>📞 {phone}</div>
      <div>👤 {createdByName}</div>
      <br />
      <div>
        {t('Full info')}: app.yuklar.uz/load/{id}
      </div>
      <br />
      <div>{t('More loads')}: app.yuklar.uz</div>
    </StyledMessageArea>
  );
};
