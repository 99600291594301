import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getLoads, getLoad } from 'api';
import { IGetLoadsParams, IGetMyLoadsRes, ILoad } from 'types';
import { notify } from 'utils';

export const useGetLoads = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IGetMyLoadsRes>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadBoard = async (params: IGetLoadsParams) => {
    setIsLoading(true);
    try {
      const res = await getLoads(params);
      if (params.page === 0) {
        setData(res);
        return;
      }
      setData(prev => ({
        ...res,
        content: prev?.content.length
          ? [...prev.content, ...res.content]
          : res.content,
      }));
    } catch (error) {
      notify('error', t('Failed to get loads'));
    } finally {
      setIsLoading(false);
    }
  };

  return { data, loadBoard, isLoading };
};

export const useGetLoad = (id?: string) => {
  if (!id)
    return useQuery<ILoad>(['getLoad', id], async () => {
      return {} as ILoad;
    });
  return useQuery<ILoad>(
    ['getLoad', id],
    async () => {
      const res = await getLoad(id);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};
