import { createContext, useState, useMemo, useEffect, FC } from 'react';
import { IUser } from 'types';
import { setItem, getItem } from 'utils';

export interface UserContextType {
  phone: string;
  name: string;
  isAuthenticated: boolean;
  updateUser: (_user: IUser) => void;
}

interface IUserProviderProps {
  children: React.ReactNode;
}

export const UserContext = createContext<UserContextType>({
  phone: '',
  name: '',
  isAuthenticated: false,
  updateUser: () => {},
});

export const UserProvider: FC<IUserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<IUser | null>({
    id: '',
    phone: '',
    name: '',
  });

  const updateUser = (user: IUser) => {
    setUserData(user);
    setItem('userData', JSON.stringify(user));
  };

  const contextValue = useMemo(() => {
    return {
      phone: userData?.phone || '',
      name: userData?.name || '',
      isAuthenticated: Boolean(userData?.phone) && !!getItem('token'),
      updateUser,
    };
  }, [userData]);

  useEffect(() => {
    // Check if userData exists in localStorage
    const storedUserData = getItem('userData');

    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
