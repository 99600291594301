import { FC, useContext } from 'react';
import { Tabs, Tab, Typography, Container } from '@mui/material';
import { UserContext } from 'app';
import { AddLoadButton } from 'components';

import {
  StyledProfileHeaderContainer,
  StyledNameContainer,
} from './styledComponents';
import { useTranslation } from 'react-i18next';

const TABS = [
  {
    id: 0,
    label: 'My details',
  },
  {
    id: 1,
    label: 'My password',
  },
  {
    id: 2,
    label: 'My loads',
  },
];

const a11yProps = (index: number) => {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
};

interface IProfileHeaderProps {
  activeTab: number;
  onChangeTab: (_event: React.SyntheticEvent, _newValue: number) => void;
}

export const ProfileHeader: FC<IProfileHeaderProps> = ({
  activeTab,
  onChangeTab,
}) => {
  const {t}=useTranslation()
  const { name } = useContext(UserContext);

  return (
    <StyledProfileHeaderContainer>
      <Container className='container' fixed maxWidth='xl'>
        <StyledNameContainer>
          <Typography variant='h5' variantMapping={{ h5: 'p' }} className='name'>
            {name}
          </Typography>
          <AddLoadButton />
        </StyledNameContainer>

        <Tabs
          value={activeTab}
          onChange={onChangeTab}
          aria-label='profile tabs'
          variant='scrollable'
          scrollButtons='auto'
        >
          {TABS.map((tab, index) => (
            <Tab key={tab.id} label={t(tab.label)} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Container>
    </StyledProfileHeaderContainer>
  );
};
