import {
  MailIcon,
  PhoneIcon,
  TelegramIcon,
  InstragramIcon,
  FacebookIcon,
  LinkedinIcon,
} from 'assets';

interface IContactData {
  link: string;
  text: string;
  icon: JSX.Element;
}

export const FooterContactData: Array<IContactData> = [
  {
    link: 'tel:+998(20) 007-92-00',
    text: '+998(20) 007-92-00',
    icon: <PhoneIcon />,
  },
  {
    link: 'mailto:yuklaruzofficial@gmail.com',
    text: 'yuklaruzofficial@gmail.com',
    icon: <MailIcon />,
  },
  {
    link: 'https://t.me/yuklar_uz_official',
    text: 'Telegram',
    icon: <TelegramIcon />,
  },
  {
    link: 'https://www.instagram.com/yuklar_uz_official/',
    text: 'Instagram',
    icon: <InstragramIcon />,
  },
  {
    link: 'https://www.facebook.com/groups/1079088246528692',
    text: 'Facebook',
    icon: <FacebookIcon />,
  },
  {
    link: 'https://www.linkedin.com/company/cargoway-logistic-solutions',
    text: 'Linkedin',
    icon: <LinkedinIcon />,
  },
];
