import { FC } from 'react';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { TextField, Timer } from 'UI';

interface ITextFieldProps extends Omit<TextFieldProps, 'sx'> {
  errorMessage?: string;
  showTimer: boolean;
  onTimerComplete: () => void;
}

export const InputWithTimer: FC<ITextFieldProps> = ({
  showTimer,
  onTimerComplete,
  ...props
}) => {
  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: showTimer && (
          <InputAdornment position='start'>
            <Timer onTimerComplete={onTimerComplete} />
          </InputAdornment>
        ),
      }}
    />
  );
};
