import { styled } from '@mui/system';
import {
  Box,
  Card,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

export const StyledContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.white,
  borderRadius: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  cursor: 'pointer',
}));

export const StyledMainInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  '& .wayIconBox': {
    '& svg': {
      width: '100%',
    },
  },
  '& .space': {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
      '&.spaceWithLg': {
        display: 'block',
      },
    },
  },
}));

export const StyledPriceButton = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  border: '1px dashed #F59E0C',
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(245, 158, 12, 0.10)',
  fontSize: '1rem',
  lineHeight: 1,
}));

export const StyledPhoneButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3, 0),
  boxShadow: 'none',
  fontSize: '1rem',
  lineHeight: 1,
}));

export const StyledBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const StyledInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  '& .label': {
    color: theme.palette.gray[400],
  },
  '& .value': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.black,
    fontWeight: 500,
    lineHeight: theme.spacing(6),
  },
  '& .textArea': {
    backgroundColor: theme.palette.gray[100],
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1.5),
  },
}));

export const StyledFooter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledAuthorFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .switch': {
    width: '6.5rem',
  },
  '& .buttons': {
    display: 'flex',
    gap: theme.spacing(3),
    '& button': {
      minWidth: '8rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& .switch': {
      width: '100%',
    },
  },
}));

export const StyledViewDialogContainer = styled(Box)(({ theme }) => ({
  width: '360px',
  backgroundColor: theme.palette.white,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '360px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.gray[200]}`,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.gray[200]}`,
}));

export const StyledMessageArea = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  padding: theme.spacing(3, 5),
  marginTop: theme.spacing(5),
  borderRadius: theme.spacing(1),
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  '& button': {
    width: '10rem',
  },
}));
