import { FC, useContext } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Typography, Box } from '@mui/material';
import { FilterContext } from 'app';
import { PaymentSelect, Switch, TextField, Button } from 'UI';
import { IGetLoadsParams } from 'types';

import {
  StyledAdvancedFilterContainer,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  StyledFormItem,
} from './styledComponents';
import { useTranslation } from 'react-i18next';

interface IAdvancedFilterProps {
  open: boolean;
  onClose: () => void;
}

export const AdvancedFilter: FC<IAdvancedFilterProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { filterObject, applyFilter } = useContext(FilterContext);
  const { handleSubmit, control } = useForm({});

  const submitFilter: SubmitHandler<Partial<IGetLoadsParams>> = data => {
    applyFilter(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledAdvancedFilterContainer>
        <StyledDialogTitle variant='h6'>
          {t('Advanced Filters')}
        </StyledDialogTitle>
        <form onSubmit={handleSubmit(submitFilter)}>
          <StyledDialogContent>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('City')}
              </Typography>
              <Box className='flex'>
                <Controller
                  control={control}
                  defaultValue={filterObject.addressFrom}
                  name='addressFrom'
                  render={({ field }) => {
                    const { name, onChange, onBlur, ref, value } = field;
                    return (
                      <TextField
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        value={value}
                        placeholder={t('From')}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name='addressTo'
                  defaultValue={filterObject.addressTo}
                  render={({ field }) => {
                    const { name, onChange, onBlur, ref, value } = field;
                    return (
                      <TextField
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        value={value}
                        placeholder={t('To')}
                      />
                    );
                  }}
                />
              </Box>
            </StyledFormItem>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('Price')}
              </Typography>
              <Box className='flex'>
                <Controller
                  control={control}
                  name='priceFrom'
                  defaultValue={filterObject.priceFrom}
                  render={({ field }) => {
                    const { name, onChange, onBlur, ref, value } = field;
                    return (
                      <TextField
                        label={t('priceFrom')}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        value={value}
                        placeholder='2 000'
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name='priceTo'
                  defaultValue={filterObject.priceTo}
                  render={({ field }) => {
                    const { name, onChange, onBlur, ref, value } = field;
                    return (
                      <TextField
                        label={t('priceTo')}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        value={value}
                        placeholder='5 000'
                      />
                    );
                  }}
                />
              </Box>
            </StyledFormItem>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('Payment method')}
              </Typography>

              <PaymentSelect
                control={control}
                defaultValue={filterObject.paymentType}
              />
            </StyledFormItem>
            <Controller
              control={control}
              name='advancePayment'
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Switch
                    label={t('Advance payment')}
                    checked={!!value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </StyledDialogContent>
        </form>
        <StyledDialogActions>
          <Button onClick={onClose} variant='outlined' color='primaryBlue'>
            {t('Close')}
          </Button>
          <Button
            onClick={handleSubmit(submitFilter)}
            variant='contained'
            color='primaryBlue'
          >
            {t('Apply')}
          </Button>
        </StyledDialogActions>
      </StyledAdvancedFilterContainer>
    </Dialog>
  );
};
