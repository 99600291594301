import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { PlusIcon } from 'assets';
import { UserContext, PATHS } from 'app';
import { Button } from 'UI';

import { StyledButtonContainer } from './styledComponents';

export const AddLoadButton: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isAuthenticated } = useContext(UserContext);

  const onAddButtonClick = () => {
    if (!isAuthenticated) {
      navigate(PATHS.auth);
      return;
    }
    navigate(PATHS.createLoad);
  };

  return (
    <StyledButtonContainer>
      <Button
        variant='contained'
        color='primaryBlue'
        startIcon={<PlusIcon />}
        size='large'
        onClick={onAddButtonClick}
      >
        <Typography className='text' variant='body1'>
          {t('Add Load')}
        </Typography>
      </Button>
    </StyledButtonContainer>
  );
};
