import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { UserContext, PATHS } from 'app';
import { UserIcon } from 'assets';
import { Button } from 'UI';

import { StyledLoginButtonBox, StyledProfileBox } from './styledComponents';
import { useTranslation } from 'react-i18next';

export const Profile: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, name } = useContext(UserContext);
  if (!isAuthenticated) {
    return (
      <Link to={PATHS.auth}>
        <StyledLoginButtonBox>
          <Button variant='contained' size='large' color='primaryBlue'>
            {t('Login')}
          </Button>
          <Box className='userProfileIcon'>
            <UserIcon />
          </Box>
        </StyledLoginButtonBox>
      </Link>
    );
  }

  return (
    <Link to={PATHS.profile}>
      <StyledProfileBox>
        <UserIcon />
        <Typography variant='body2' className='name'>
          {name}
        </Typography>
      </StyledProfileBox>
    </Link>
  );
};
