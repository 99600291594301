import { Container, Typography } from '@mui/material';

import { FooterContactData } from 'utils';

import { StyledFooter, StyledItem } from './styledComponents';

export const Footer = () => {
  return (
    <StyledFooter>
      <Container fixed maxWidth='xl' className='container'>
        {FooterContactData.map(item => (
          <StyledItem
            key={item.link}
            href={item.link}
            target='_blank'
            rel='noreferrer'
          >
            {item.icon}
            <Typography variant='body2'>{item.text}</Typography>
          </StyledItem>
        ))}
      </Container>
    </StyledFooter>
  );
};
