import { FC, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useResetPassword, useCheckSmsCode } from 'hooks';
import { getCleanedPhone, theme, notify } from 'utils';
import { PhoneInput, TextField, InputWithTimer, Button } from 'UI';
import { IGetSmsCodeRes, IResetPassword } from 'types';

import { StyledContainer } from './styledComponents';
import {
  CARDS,
  ResetCardSteps,
  RESET_CARD_STEPS,
  IAuthCardProps,
} from '../types';
import { getSmsCode } from 'api';
import { useTranslation } from 'react-i18next';

interface IResetPasswordForm extends Pick<IResetPassword, 'newPassword'> {
  confirmNewPassword: string;
}

export const ResetPasswordCard: FC<IAuthCardProps> = ({ changeCard }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<ResetCardSteps>(
    RESET_CARD_STEPS.PHONE,
  );
  const [uuid, setUuid] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [reset, setReset] = useState<IResetPasswordForm>({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [showResend, setShowResend] = useState<boolean>(false);

  const { isLoading: isResetLoading, mutateAsync: resetPassword } =
    useResetPassword();
  const { isLoading: isCheckSmsLoading, mutateAsync: checkSmsCode } =
    useCheckSmsCode();

  const getSubTitle = () => {
    switch (activeStep) {
      case RESET_CARD_STEPS.PHONE:
        return t('Please enter your phone number.');
      case RESET_CARD_STEPS.CODE:
        return t('An SMS with a confirmation code has been sent to number', {
          phone,
        });
      case RESET_CARD_STEPS.RESET:
        return t('Enter your new password');
      default:
        return '';
    }
  };

  const resendCode = () => {};

  const onTimerComplete = () => {
    setShowResend(true);
  };

  const submitForm = () => {
    if (activeStep === RESET_CARD_STEPS.PHONE) {
      getSmsCode(getCleanedPhone(phone)).then((res: IGetSmsCodeRes) => {
        setUuid(res.uuid);
        setActiveStep(RESET_CARD_STEPS.CODE);
      });
      setActiveStep(RESET_CARD_STEPS.CODE);
    } else if (activeStep === RESET_CARD_STEPS.CODE) {
      checkSmsCode({ uuid, code }).then(res => {
        setUuid(res.uuid);
        setActiveStep(RESET_CARD_STEPS.RESET);
      });
    } else {
      if (reset.newPassword !== reset.confirmNewPassword) {
        notify('error', t('Passwords do not match'));
        return;
      }
      resetPassword({
        uuid,
        phone: getCleanedPhone(phone),
        newPassword: reset.newPassword,
      })
        .then(res => {
          notify('success', t('Password has been reset'));
          changeCard(CARDS.LOGIN);
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  };

  return (
    <StyledContainer>
      <Box className='titleBlock'>
        <Typography variant='h4'>{t('Reset password')}</Typography>
        <Typography variant='body1'>{getSubTitle()}</Typography>
      </Box>
      {activeStep === RESET_CARD_STEPS.PHONE && (
        <PhoneInput value={phone} onChange={e => setPhone(e.target.value)} />
      )}
      {activeStep === RESET_CARD_STEPS.CODE && (
        <InputWithTimer
          onTimerComplete={onTimerComplete}
          label={t('Confirmation code')}
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder=''
          name='code'
          required
          showTimer
        />
      )}
      {activeStep === RESET_CARD_STEPS.RESET && (
        <PasswordStepComponent
          resetPasswordForm={reset}
          handleFieldChange={(field, value) =>
            setReset(prev => ({ ...prev, [field]: value }))
          }
        />
      )}
      {showResend && activeStep === RESET_CARD_STEPS.CODE && (
        <Typography
          align='right'
          variant='captionBold'
          color={theme.palette.primaryBlue.main}
          className='link'
          onClick={() => resendCode}
          sx={{ marginTop: '-16px', display: 'block' }}
        >
          {t('Resend code')}
        </Typography>
      )}
      <Button
        variant='contained'
        color='primaryBlue'
        size='medium'
        onClick={submitForm}
        isLoading={isResetLoading || isCheckSmsLoading}
        disabled={isResetLoading || isCheckSmsLoading}
      >
        {t('Continue')}
      </Button>
      <Box className='bottomLink'>
        <Typography variant='caption'>{t('New user?')}</Typography>
        <Typography
          variant='captionBold'
          color={theme.palette.primaryBlue.main}
          className='link'
          onClick={() => changeCard(CARDS.REGISTER)}
        >
          {t('Registration')}
        </Typography>
      </Box>
    </StyledContainer>
  );
};

interface IPasswordStepProps {
  resetPasswordForm: IResetPasswordForm;
  handleFieldChange: <T extends keyof IResetPasswordForm>(
    _fieldName: T,
    _newValue: IResetPasswordForm[T],
  ) => void;
}

const PasswordStepComponent: FC<IPasswordStepProps> = ({
  resetPasswordForm,
  handleFieldChange,
}) => {
  const { t } = useTranslation();
  return (
    <form>
      <TextField
        label={t('New password')}
        value={resetPasswordForm.newPassword}
        onChange={e => handleFieldChange('newPassword', e.target.value)}
        type='password'
        name='password'
        required
      />
      <TextField
        label={t('Confirm password')}
        value={resetPasswordForm.confirmNewPassword}
        onChange={e => handleFieldChange('confirmNewPassword', e.target.value)}
        type='password'
        name='passwordConfirm'
        required
      />
    </form>
  );
};
