import { FC, useContext } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Divider, Typography } from '@mui/material';
import { UserContext } from 'app';
import { useUpdateUserDetails } from 'hooks';
import { IUser } from 'types';
import { TextField, Button } from 'UI';

import {
  StyledContainer,
  StyledBody,
  StyledFooter,
  StyledFormItem,
} from '../profileStyledComponents';
import { notify } from 'utils';
import { useTranslation } from 'react-i18next';

export const ProfileMyDetails: FC = () => {
  const { t } = useTranslation();
  const { name, phone, updateUser: updateLocally } = useContext(UserContext);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: name,
      phone: phone,
    },
  });
  const { isLoading, mutateAsync: updateUser } = useUpdateUserDetails();

  const resetData = () => {
    reset();
  };

  const saveData: SubmitHandler<Partial<IUser>> = data => {
    if (!data.name) {
      notify('error', t('Name is required'));
      return;
    }
    updateUser({ name: data.name })
      .then(res => {
        notify('success', t('Details updated'));
        updateLocally(res);
      })
      .catch(() => {});
  };

  return (
    <StyledContainer>
      <StyledBody>
        <StyledFormItem>
          <Typography variant='body1' variantMapping={{ body1: 'label' }}>
            {t("Full name")}
          </Typography>
          <Controller
            control={control}
            name='name'
            render={({ field }) => {
              const { name, onChange, onBlur, ref, value } = field;
              return (
                <TextField
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  value={value}
                  placeholder= {t("Full name")}
                  className='input'
                />
              );
            }}
          />
        </StyledFormItem>
        <Divider orientation='horizontal' />
        <StyledFormItem>
          <Typography variant='body1' variantMapping={{ body1: 'label' }}>
            {t("Phone number")}
          </Typography>
          <Controller
            control={control}
            name='phone'
            disabled
            render={({ field }) => {
              const { name, onChange, onBlur, ref, value } = field;
              return (
                <TextField
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  value={value}
                  className='input'
                  disabled
                />
              );
            }}
          />
        </StyledFormItem>
      </StyledBody>
      <StyledFooter>
        <Button
          onClick={resetData}
          variant='outlined'
          color='primaryBlue'
          size='large'
          className='button'
        >
          {t("Cancel")}
        </Button>
        <Button
          variant='contained'
          color='primaryBlue'
          size='large'
          className='button'
          onClick={handleSubmit(saveData)}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t("Save")}
        </Button>
      </StyledFooter>
    </StyledContainer>
  );
};
