import { FC, useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from 'app';
import { useGetLoads } from 'hooks';
import { LoadIcon, NotFound } from 'assets';
import { LoadCard } from 'components';

import { StyledLoadBoardContainer } from './styledComponents';
import { Loader, Button } from 'UI';
import { useTranslation } from 'react-i18next';

export const LoadBoard: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { filterObject, handleFieldChange } = useContext(FilterContext);
  const {
    data: { content, last, empty } = {
      content: [],
      last: true,
      empty: false,
    },
    loadBoard,
    isLoading,
  } = useGetLoads();

  useEffect(() => {
    loadBoard(filterObject);
  }, [filterObject]);

  const getNextPage = () => {
    handleFieldChange('page', filterObject.page + 1);
  };

  const navigateToLoad = (id: string) => {
    navigate(`/load/${id}`);
  };

  if (isLoading && !content.length)
    return (
      <StyledLoadBoardContainer>
        <Loader size={60} />
      </StyledLoadBoardContainer>
    );

  return (
    <StyledLoadBoardContainer>
      {content.map(load => (
        <LoadCard
          key={load.id}
          {...load}
          onClick={() => navigateToLoad(load.id)}
          className='loadCard'
        />
      ))}
      {empty && (
        <Box className='notFound'>
          <NotFound />
          <Typography variant='h5' align='center'>
            {t('No loads found with current filter')}
          </Typography>
        </Box>
      )}
      {!last && (
        <Box className='buttonBox'>
          <Button
            variant='outlined'
            color='primaryBlue'
            size='large'
            startIcon={isLoading ? <Loader size={12} /> : <LoadIcon />}
            onClick={getNextPage}
          >
            {t('Load More')}
          </Button>
        </Box>
      )}
    </StyledLoadBoardContainer>
  );
};
