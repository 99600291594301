export type AuthCardType = 'login' | 'register' | 'resetPassword';

type CardKey = 'LOGIN' | 'REGISTER' | 'RESET_PASSWORD';

export const CARDS: Record<CardKey, AuthCardType> = {
  LOGIN: 'login',
  REGISTER: 'register',
  RESET_PASSWORD: 'resetPassword',
};

export interface IAuthCardProps {
  changeCard: (_newCardType: AuthCardType) => void;
}

export type ResetCardSteps = 'phone' | 'code' | 'reset';

type ResetCardStepKey = 'PHONE' | 'CODE' | 'RESET';

export const RESET_CARD_STEPS: Record<ResetCardStepKey, ResetCardSteps> = {
  PHONE: 'phone',
  CODE: 'code',
  RESET: 'reset',
};

export type RegisterCardSteps = 'phone' | 'code' | 'register';

type RegisterCardStepKey = 'PHONE' | 'CODE' | 'REGISTER';

export const REGISTER_CARD_STEPS: Record<
  RegisterCardStepKey,
  RegisterCardSteps
> = {
  PHONE: 'phone',
  CODE: 'code',
  REGISTER: 'register',
};
