import { FC } from 'react';

export const RussianIcon: FC = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_460_1292)'>
        <path
          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
          fill='#F0F0F0'
        />
        <path
          d='M15.5025 10.7822C15.8241 9.91557 16.0001 8.9782 16.0001 7.99963C16.0001 7.02107 15.8241 6.0837 15.5025 5.21704H0.497716C0.176153 6.0837 0.00012207 7.02107 0.00012207 7.99963C0.00012207 8.9782 0.176153 9.91557 0.497716 10.7822L8.00012 11.4779L15.5025 10.7822Z'
          fill='#0052B4'
        />
        <path
          d='M8.00021 15.9996C11.4399 15.9996 14.3723 13.8286 15.5026 10.7822H0.497803C1.62815 13.8286 4.56049 15.9996 8.00021 15.9996Z'
          fill='#D80027'
        />
      </g>
      <defs>
        <clipPath id='clip0_460_1292'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
