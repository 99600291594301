import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 0),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
  },
}));
