import { ReactNode, createContext, useMemo, useState } from 'react';
import { IGetLoadsParams } from 'types';
import {
  DEFAULT_PAGE_SIZE,
  PAYMENT_METHOD_VALUES,
  SORT_OPTION_VALUES,
} from 'utils';

interface IFilterContextType {
  filterObject: IGetLoadsParams;
  isLastPage?: boolean;
  handleFieldChange: <T extends keyof IGetLoadsParams>(
    _fieldName: T,
    _newValue: IGetLoadsParams[T],
  ) => void;
  applyFilter: (_newFilter: Partial<IGetLoadsParams>) => void;
}

export const FilterContext = createContext<IFilterContextType>({
  filterObject: {
    countryFrom: '',
    countryTo: '',
    addressFrom: '',
    addressTo: '',
    priceFrom: '',
    priceTo: '',
    advancePayment: false,
    paymentType: PAYMENT_METHOD_VALUES.ALL,
    sortBy: SORT_OPTION_VALUES.N,
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  isLastPage: false,
  handleFieldChange: () => {},
  applyFilter: () => {},
});

interface IFilterProviderProps {
  children: ReactNode;
}

export const FilterProvider = ({ children }: IFilterProviderProps) => {
  const [filterObject, setFilterObject] = useState<IGetLoadsParams>({
    countryFrom: '',
    countryTo: '',
    addressFrom: '',
    addressTo: '',
    priceFrom: '',
    priceTo: '',
    advancePayment: false,
    paymentType: PAYMENT_METHOD_VALUES.ALL,
    sortBy: SORT_OPTION_VALUES.N,
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const handleFieldChange = <T extends keyof IGetLoadsParams>(
    fieldName: T,
    newValue: IGetLoadsParams[T],
  ) => {
    setFilterObject(prev => ({ ...prev, [fieldName]: newValue }));
  };

  const applyFilter = (newFilter: Partial<IGetLoadsParams>) => {
    setFilterObject(prevState => ({
      ...prevState,
      ...newFilter,
      page: 0,
    }));
  };

  const contextValue = useMemo(() => {
    return {
      filterObject,
      handleFieldChange,
      applyFilter,
    };
  }, [filterObject]);

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
