export const OriginIcon = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_403_11903)'>
        <path
          d='M3 12C3 6.47715 7.47715 2 13 2C18.5228 2 23 6.47715 23 12C23 17.5228 18.5228 22 13 22C7.47715 22 3 17.5228 3 12Z'
          fill='white'
          stroke='#257BFF'
        />
        <path
          d='M8 12C8 9.23858 10.2386 7 13 7C15.7614 7 18 9.23858 18 12C18 14.7614 15.7614 17 13 17C10.2386 17 8 14.7614 8 12Z'
          fill='#F59E0C'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_403_11903'
          x='0.5'
          y='0.5'
          width='25'
          height='25'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0784314 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_403_11903'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_403_11903'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
