import { FC } from 'react';
import { styled } from '@mui/system';
import {
  TextField as MUITextField,
  TextFieldProps,
  Box,
  Typography,
} from '@mui/material';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  '& .errorMessage': {
    color: theme.palette.error.main,
  },
}));

const StyledTextField = styled(MUITextField)(({ theme }) => ({
  '& label': {
    fontWeight: 500,
    color: theme.palette.gray[600],
  },
  '& .MuiTextField-root': {
    width: '100%',
  },
}));

interface ITextFieldProps extends Omit<TextFieldProps, 'sx'> {
  errorMessage?: string;
}

export const TextField: FC<ITextFieldProps> = ({
  label,
  variant = 'outlined',
  color = 'primary',
  placeholder = '',
  value,
  onChange,
  required = false,
  error,
  errorMessage,
  ...props
}) => {
  return (
    <StyledContainer>
      <StyledTextField
        label={label}
        variant={variant}
        color={color}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        error={error}
        {...props}
      />
      {error && (
        <Typography className='errorMessage'>{errorMessage}</Typography>
      )}
    </StyledContainer>
  );
};
