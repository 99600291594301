import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  padding: theme.spacing(4, 0),
  '& button': {
    minWidth: '10rem',
    '& .text': {
      color: theme.palette.white,
    },
  },
}));
