import { IGetLoadsParams, ILoadReq } from 'types';
import { http, methods } from './config';

export const getLoads = (params: IGetLoadsParams) => {
  const config = {
    method: methods.get,
    url: `/load/board/get-all`,
    params,
  };
  return http(config);
};

export const getLoad = (id: string) => {
  const config = {
    method: methods.get,
    url: `/load/board/${id}`,
  };
  return http(config);
};

export const createLoad = (data: ILoadReq) => {
  const config = {
    method: methods.post,
    url: `/load`,
    data,
  };
  return http(config);
};

export const updateLoad = (data: ILoadReq) => {
  const config = {
    method: methods.put,
    url: `/load/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteLoad = (id: string) => {
  const config = {
    method: methods.delete,
    url: `/load/${id}`,
  };
  return http(config);
};
