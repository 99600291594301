export const PhoneIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.5559 12.906L16.1009 13.359C16.1009 13.359 15.0179 14.435 12.0629 11.497C9.10788 8.559 10.1909 7.48299 10.1909 7.48299L10.4769 7.19699C11.1839 6.49499 11.2509 5.36699 10.6339 4.54299L9.37388 2.85999C8.60988 1.83999 7.13488 1.70499 6.25988 2.57499L4.68988 4.13499C4.25688 4.56699 3.96688 5.12499 4.00188 5.74499C4.09188 7.33199 4.80988 10.745 8.81388 14.727C13.0609 18.949 17.0459 19.117 18.6749 18.965C19.1909 18.917 19.6389 18.655 19.9999 18.295L21.4199 16.883C22.3799 15.93 22.1099 14.295 20.8819 13.628L18.9719 12.589C18.1659 12.152 17.1859 12.28 16.5559 12.906Z'
        fill='#1A1D1F'
      />
    </svg>
  );
};
