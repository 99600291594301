import { FC } from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface ILoaderProps extends CircularProgressProps {
  size?: number;
}

export const Loader: FC<ILoaderProps> = ({
  size = 40,
  color = 'primaryBlue',
}) => {
  return (
    <StyledContainer>
      <CircularProgress color={color} size={size} />
    </StyledContainer>
  );
};
