import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserContext } from 'app';

import { IRoute } from './types';
import { ROUTES } from './routes';

export const Router = () => {
  const { isAuthenticated } = useContext(UserContext);

  const routesMap = Object.values(ROUTES);
  const getRouteElement = (
    element: JSX.Element,
    openRoute: boolean,
    closeRoute: boolean,
  ) => {
    if (openRoute && isAuthenticated) {
      return <Navigate replace to={ROUTES.home!.path} />;
    }
    if (closeRoute && !isAuthenticated) {
      return <Navigate replace to={ROUTES.auth!.path} />;
    }
    return element;
  };

  const generateRoute = ({
    title,
    path,
    element,
    index,
    childRoute,
    openRoute = false,
    closeRoute = false,
  }: IRoute) => {
    if (childRoute) {
      return (
        <Route
          key={title}
          path={path}
          element={getRouteElement(element, openRoute, closeRoute)}
        >
          {Object.values(childRoute).map(child => generateRoute(child))}
        </Route>
      );
    }
    return (
      <Route
        key={title}
        index={index}
        path={path}
        element={getRouteElement(element, openRoute, closeRoute)}
      />
    );
  };

  const pageRoutes = routesMap.map(generateRoute);

  return <Routes>{pageRoutes}</Routes>;
};
