/* eslint-disable no-unused-vars */
import {
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material/styles/createPalette';
import { createTheme } from '@mui/material/styles';

type ColorShadow =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 1000;

type IShadowColor = Record<ColorShadow, string>;

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryBlue: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    primaryBlue: true;
    white: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionBold: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    primaryBlue: PaletteColor;
    gray: IShadowColor;
    white: string;
    black: string;
    green: string;
  }

  interface PaletteOptions {
    primaryBlue: PaletteColorOptions;
    gray: IShadowColor;
    white: string;
    black: string;
    green: string;
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }

  interface TypographyVariants {
    captionBold?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionBold?: React.CSSProperties;
  }
}

export const theme = createTheme({
  palette: {
    primaryBlue: {
      main: '#257BFF',
      contrastText: '#ffffff',
      dark: '#143e80',
      light: '#4d9dff',
    },
    gray: {
      50: '#FAFAFA',
      100: '#F6F7F9',
      200: '#E5E7EA',
      300: '#CED2D6',
      400: '#9EA5AD',
      500: '#676E76',
      600: '#596066',
      700: '#454C52',
      800: '#383F45',
      900: '#24292E',
      1000: '#1A1D1F',
    },
    white: '#FFFFFF',
    black: '#000000',
    green: '#22C55D',
  },
  typography: {
    h4: {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 600,
      color: '#1A1D1F', // gray[1000]
    },
    h5: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      color: '#1A1D1F', // gray[1000]
    },
    h6: {
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: 500,
      color: '#1A1D1F', // gray[1000]
    },
    body1: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#454C52', // gray[1000]
    },
    body2: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
      color: '#1A1D1F', // gray[700]
    },
    caption: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#596066', // gray[600]
    },
    captionBold: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      color: '#1A1D1F', // gray[1000]
    },
  },
  spacing: 4,
  breakpoints: {
    values: {
      xs: 320,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1280,
      xxl: 1520,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          '&::-webkit-scrollbar': {
            width: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});
