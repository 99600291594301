import {
  IChangePassword,
  ILogin,
  IRegister,
  ICheckSmsCode,
  IResetPassword,
} from 'types';
import { http, methods } from './config';

export const login = (data: ILogin) => {
  const config = {
    method: methods.post,
    url: `/auth/login`,
    data,
  };
  return http(config);
};

export const getSmsCode = (phone: string) => {
  const config = {
    method: methods.get,
    url: `/auth/send-code/${phone}`,
  };
  return http(config);
};

export const checkSmsCode = (data: ICheckSmsCode) => {
  const config = {
    method: methods.post,
    url: `/auth/verify-code`,
    data,
  };
  return http(config);
};

export const register = (data: IRegister) => {
  const config = {
    method: methods.post,
    url: `/auth/signup`,
    data,
  };
  return http(config);
};

export const resetPassword = (data: IResetPassword) => {
  const config = {
    method: methods.post,
    url: `/auth/reset-password`,
    data,
  };
  return http(config);
};

// TODO: not done yet
export const changePassword = (data: IChangePassword) => {
  const config = {
    method: methods.post,
    url: `/auth/change_password`,
    data,
  };
  return http(config);
};
