import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { useGetMyLoads } from 'hooks';
import { LoadIcon, NotFound, PlusIcon } from 'assets';
import { IGetMyLoadsParams } from 'types';
import { LoadCard } from 'components';
import { PATHS } from 'app';
import { DEFAULT_PAGE_SIZE } from 'utils';
import { Loader, Button } from 'UI';

import { StyledContainer } from './styledComponents';

export const ProfileMyLoads: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useState<IGetMyLoadsParams>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const {
    data: { content, last, empty } = { content: [], last: true, empty: false },
    getLoads,
    resetLoads,
    isLoading,
  } = useGetMyLoads();

  useEffect(() => {
    getLoads(params);
    return () => {
      setParams({ page: 0, pageSize: DEFAULT_PAGE_SIZE });
      resetLoads();
    };
  }, []);

  const getNextPage = () => {
    setParams(prev => ({ ...prev, page: prev.page + 1 }));
    getLoads({ ...params, page: params.page + 1 });
  };

  const redirectUser = () => {
    navigate(PATHS.createLoad);
  };

  if (isLoading) {
    return (
      <StyledContainer>
        <Loader size={60} />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      {content.map(load => (
        <LoadCard key={load.id} {...load} isAuthorMode />
      ))}
      {!last && (
        <Box className='buttonBox'>
          <Button
            variant='outlined'
            color='primaryBlue'
            size='large'
            startIcon={<LoadIcon />}
            onClick={getNextPage}
          >
            {t('Load More')}
          </Button>
        </Box>
      )}
      {empty && (
        <Box className='notFound'>
          <NotFound />
          <Typography variant='h5'>{t('No loads found')}</Typography>
          <Typography variant='body1'>
            {t('You have not created any loads yet')}
          </Typography>
          <Button
            variant='contained'
            color='primaryBlue'
            size='large'
            startIcon={<PlusIcon />}
            onClick={redirectUser}
          >
            {t('Add Load')}
          </Button>
        </Box>
      )}
    </StyledContainer>
  );
};
