import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(5, 0),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
  },
  '& .buttonBox': {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      width: '10rem',
    },
  },
  '& .notFound': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));
