import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import {
  getCleanedPhone,
  notify,
  theme,
  setItem,
  getItem,
  removeItem,
} from 'utils';
import { useCheckSmsCode, useRegister } from 'hooks';
import { getSmsCode } from 'api';
import { IRegister, IGetSmsCodeRes } from 'types';
import { PhoneInput, InputWithTimer, TextField, Button } from 'UI';

import { StyledContainer } from './styledComponents';
import {
  CARDS,
  IAuthCardProps,
  REGISTER_CARD_STEPS,
  RegisterCardSteps,
} from '../types';

interface IRegisterForm extends Pick<IRegister, 'name' | 'password'> {
  confirmPassword: string;
}

export const RegisterCard: FC<IAuthCardProps> = ({ changeCard }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<RegisterCardSteps>(
    REGISTER_CARD_STEPS.PHONE,
  );
  const [uuid, setUuid] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [registerForm, setRegisterForm] = useState<IRegisterForm>({
    name: '',
    password: '',
    confirmPassword: '',
  });
  const [showResend, setShowResend] = useState<boolean>(false);
  const [restartTimer, setRestartTimer] = useState(false);

  const { isLoading: isRegisterLoading, mutateAsync: register } = useRegister();
  const { isLoading: isCheckSmsLoading, mutateAsync: checkSmsCode } =
    useCheckSmsCode();

  useEffect(() => {
    const activeRegisterStep = getItem('activeRegisterStep');
    if (activeRegisterStep) {
      setActiveStep(activeRegisterStep as RegisterCardSteps);
    }
  }, []);

  const getSubTitle = () => {
    switch (activeStep) {
      case REGISTER_CARD_STEPS.PHONE:
        return t('Please enter your phone number.');
      case REGISTER_CARD_STEPS.CODE:
        return t('An SMS with a confirmation code has been sent to number', {
          phone,
        });
      case REGISTER_CARD_STEPS.REGISTER:
        return t('Welcome! Please enter your details.');
      default:
        return '';
    }
  };

  const resendCode = () => {
    setShowResend(false);
    setRestartTimer(!restartTimer);
  };

  const onTimerComplete = () => {
    setShowResend(true);
  };

  const submitForm = () => {
    if (activeStep === REGISTER_CARD_STEPS.PHONE) {
      ReactGA.event({ category: 'User', action: 'GetSmsCode' });
      getSmsCode(getCleanedPhone(phone)).then((res: IGetSmsCodeRes) => {
        setUuid(res.uuid);
        setActiveStep(REGISTER_CARD_STEPS.CODE);
        setItem('activeRegisterStep', REGISTER_CARD_STEPS.CODE);
      });
    } else if (activeStep === REGISTER_CARD_STEPS.CODE) {
      ReactGA.event({ category: 'User', action: 'CheckSmsCode' });
      checkSmsCode({ uuid, code }).then(res => {
        setUuid(res.uuid);
        setActiveStep(REGISTER_CARD_STEPS.REGISTER);
        setItem('activeRegisterStep', REGISTER_CARD_STEPS.REGISTER);
      });
    } else {
      if (registerForm.password !== registerForm.confirmPassword) {
        notify('error', t('Passwords do not match'));
        return;
      }
      const registerData: IRegister = {
        uuid,
        phone: getCleanedPhone(phone),
        name: registerForm.name,
        password: registerForm.password,
        post: 'A',
      };
      ReactGA.event({ category: 'User', action: 'Register' });
      register(registerData)
        .then(res => {
          notify('success', t('You have been registered'));
          changeCard(CARDS.LOGIN);
          removeItem('activeRegisterStep');
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  };

  return (
    <StyledContainer>
      <Box className='titleBlock'>
        <Typography variant='h4'>{t('Register')}</Typography>
        <Typography variant='body1'>{getSubTitle()}</Typography>
      </Box>
      {activeStep === REGISTER_CARD_STEPS.PHONE && (
        <PhoneInput value={phone} onChange={e => setPhone(e.target.value)} />
      )}
      {activeStep === REGISTER_CARD_STEPS.CODE && (
        <InputWithTimer
          onTimerComplete={onTimerComplete}
          label={t('Confirmation code')}
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder=''
          name='code'
          required
          showTimer
          key={restartTimer.toString()}
        />
      )}
      {activeStep === REGISTER_CARD_STEPS.REGISTER && (
        <PasswordStepComponent
          registerForm={registerForm}
          handleFieldChange={(field, value) =>
            setRegisterForm(prev => ({ ...prev, [field]: value }))
          }
        />
      )}
      {showResend && activeStep === REGISTER_CARD_STEPS.CODE && (
        <Typography
          align='right'
          variant='captionBold'
          color={theme.palette.primaryBlue.main}
          className='link'
          onClick={() => resendCode()}
          sx={{ marginTop: '-16px' }}
        >
          {t('Resend code')}
        </Typography>
      )}
      <Button
        variant='contained'
        color='primaryBlue'
        size='medium'
        onClick={submitForm}
        isLoading={isRegisterLoading || isCheckSmsLoading}
        disabled={isRegisterLoading || isCheckSmsLoading}
      >
        {t('Continue')}
      </Button>
      <Box className='bottomLink'>
        <Typography variant='caption'>
          {t('Do you have an account?')}
        </Typography>
        <Typography
          variant='captionBold'
          color={theme.palette.primaryBlue.main}
          className='link'
          onClick={() => changeCard(CARDS.LOGIN)}
        >
          {t('Login')}
        </Typography>
      </Box>
    </StyledContainer>
  );
};

interface IPasswordStepProps {
  registerForm: IRegisterForm;
  handleFieldChange: <T extends keyof IRegisterForm>(
    _fieldName: T,
    _newValue: IRegisterForm[T],
  ) => void;
}

const PasswordStepComponent: FC<IPasswordStepProps> = ({
  registerForm,
  handleFieldChange,
}) => {
  const { t } = useTranslation();
  return (
    <form>
      <TextField
        label={t('Full name')}
        value={registerForm.name}
        onChange={e => handleFieldChange('name', e.target.value)}
        placeholder={t('Full name')}
        name='name'
        required
      />
      <TextField
        label={t('New password')}
        value={registerForm.password}
        onChange={e => handleFieldChange('password', e.target.value)}
        type='password'
        name='password'
        required
      />
      <TextField
        label={t('Confirm password')}
        value={registerForm.confirmPassword}
        onChange={e => handleFieldChange('confirmPassword', e.target.value)}
        type='password'
        name='passwordConfirm'
        required
      />
    </form>
  );
};
