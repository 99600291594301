export const EditIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.90833 4.20835C10.2667 6.50835 12.1333 8.26668 14.45 8.50002M2.5 18.3334H17.5M11.05 3.00002L4.20833 10.2417C3.95 10.5167 3.7 11.0584 3.65 11.4334L3.34167 14.1333C3.23333 15.1083 3.93333 15.775 4.9 15.6084L7.58333 15.15C7.95833 15.0834 8.48333 14.8084 8.74167 14.525L15.5833 7.28335C16.7667 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2333 1.75002 11.05 3.00002Z'
        stroke='#257BFF'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
