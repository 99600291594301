import { FC, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Header, Footer, ProfileHeader } from 'components';
import { PATHS } from 'app';

export const Profile: FC = () => {
  const getTabNumber = (pathname: string) => {
    switch (pathname) {
      case '/profile':
        return 0;
      case '/profile/password':
        return 1;
      case '/profile/my-loads':
        return 2;
      default:
        return 0;
    }
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(getTabNumber(pathname));

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        navigate(PATHS.profile);
        break;
      case 1:
        navigate(PATHS.password);
        break;
      case 2:
        navigate(PATHS.myLoads);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Header />
      <main>
        <ProfileHeader activeTab={activeTab} onChangeTab={handleChangeTab} />
        <Container fixed maxWidth='xl'>
          <Box sx={{ padding: '20px 0' }}>
            <Outlet />
          </Box>
        </Container>
      </main>
      <Footer />
    </>
  );
};
