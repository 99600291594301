import { FC, useEffect, useState } from 'react';
import { setItem } from 'utils';
import { LoginCard, RegisterCard, ResetPasswordCard } from './AuthCards';
import { AuthCardType, CARDS } from './types';

export const AuthPage: FC = () => {
  const [activeCard, setActiveCard] = useState<AuthCardType>(CARDS.LOGIN);

  const changeCard = (card: AuthCardType) => {
    setActiveCard(card);
    setItem('activeCard', card);
  };

  useEffect(() => {
    const activeCard = localStorage.getItem('activeCard');
    if (activeCard) {
      setActiveCard(activeCard as AuthCardType);
    }
  }, []);

  return (
    <>
      {activeCard === CARDS.LOGIN && <LoginCard changeCard={changeCard} />}
      {activeCard === CARDS.REGISTER && (
        <RegisterCard changeCard={changeCard} />
      )}
      {activeCard === CARDS.RESET_PASSWORD && (
        <ResetPasswordCard changeCard={changeCard} />
      )}
    </>
  );
};
