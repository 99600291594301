export const CopyIcon = () => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 13.4V17.6C16 21.1 14.6 22.5 11.1 22.5H6.9C3.4 22.5 2 21.1 2 17.6V13.4C2 9.9 3.4 8.5 6.9 8.5H11.1M16 13.4C16 9.9 14.6 8.5 11.1 8.5M16 13.4V16.5H17.1C20.6 16.5 22 15.1 22 11.6V7.4C22 3.9 20.6 2.5 17.1 2.5H12.9C9.4 2.5 8 3.9 8 7.4V8.5H11.1'
        stroke='#257BFF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
