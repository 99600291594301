import * as Pages from 'pages';
import { IRoutes, RouteTypes, PathTypes } from './types';

export const ROUTES: IRoutes = {
  auth: {
    path: '/auth',
    title: 'auth',
    element: <Pages.Auth />,
    openRoute: true,
  },
  profile: {
    path: '/profile',
    title: 'profile',
    element: <Pages.Profile />,
    closeRoute: true,
    childRoute: {
      myDetails: {
        path: '/profile',
        title: 'myDetails',
        element: <Pages.ProfileMyDetailsPage />,
        closeRoute: true,
      },
      password: {
        path: '/profile/password',
        title: 'password',
        element: <Pages.ProfilePasswordPage />,
        closeRoute: true,
      },
      myLoads: {
        path: '/profile/my-loads',
        title: 'loads',
        element: <Pages.ProfileMyLoadsPage />,
        closeRoute: true,
      },
    },
  },
  createLoad: {
    path: '/profile/create-load',
    title: 'createLoad',
    element: <Pages.CreateLoad />,
    closeRoute: true,
  },
  editLoad: {
    path: '/profile/load/:id',
    title: 'editLoad',
    element: <Pages.CreateLoad />,
    closeRoute: true,
  },
  load: {
    path: '/load/:id',
    title: 'load',
    element: <Pages.Load />,
  },
  home: {
    path: '/',
    title: 'home',
    element: <Pages.Home />,
  },
};

export const PATHS: Record<RouteTypes, PathTypes> = {
  auth: '/auth',
  profile: '/profile',
  myDetails: '/profile',
  password: '/profile/password',
  myLoads: '/profile/my-loads',
  createLoad: '/profile/create-load',
  editLoad: '/profile/load/:id',
  load: '/load/:id',
  home: '/',
};
